<template>
  <div class="dashboard-item__container ma-4 d-flex flex-column pa-2 pt-4" @click="onClick">
    <div class="d-flex justify-center align-center">
      <div class="item-icon__container">
        <v-icon x-large color="primary">{{ icon }}</v-icon>
      </div>
    </div>
    <div class="text-center title-text truncate-text py-1 pt-4">{{ title }}</div>
    <div class="text-center count">
      <div v-if="!loading">{{ value }}</div>
      <v-progress-circular indeterminate v-else color="primary" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../styles/patient-portal.scss';
.dashboard-item__container {
  height: auto;
  width: 300px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  box-shadow: $pretty-shadow;
  transition: all 0.1s;
  &:hover {
    transform: scale(1.05);
    border-color: var(--v-primary-base);
    cursor: pointer;
  }
  .item-icon__container {
    height: 75px;
    width: 75px;
    background-color: white;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .count {
    font-size: 2em;
    color: var(--v-primary-base);
  }
}
</style>
<script>
export default {
  props: {
    icon: String,
    onClick: Function,
    loading: Boolean,
    title: String,
    value: [String, Number],
  },
};
</script>
