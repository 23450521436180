<template>
  <fragment>
    <content-title>
      <template v-slot:title
        ><span style="word-break: break-all">
          {{ $t('patientPortal.home.title', { name }) }}</span
        ></template
      >
      <!-- <template v-slot:subtitle> Here's what you need to know:</template> -->
    </content-title>
    <BillingDisabledBanner v-if="isBillingDisabled" />
    <InvalidHCID v-if="healthCardExpired" />

    <UpcomingAppointmentAlert
      v-for="appointment in upcomingAppointments"
      :key="appointment.id"
      :appointment="appointment"
    />
    <main class="d-flex flex-wrap justify-center">
      <dashboard-item
        role="section"
        :title="$t('patientPortal.home.upcomingAppointments')"
        :value="appointmentCount"
        icon="mdi-calendar"
        :onClick="() => goTo('/appointments')"
        :loading="loadingAppointmentCount"
      />
      <dashboard-item
        role="section"
        :title="$t('patientPortal.home.files')"
        :value="fileCount"
        icon="mdi-file"
        :onClick="() => goTo('/files')"
        :loading="loadingFileCount"
      />
      <dashboard-item
        role="section"
        :title="$t('patientPortal.invoices.pendingCount')"
        :value="pendingCount"
        icon="mdi-invoice-text-clock-outline"
        :onClick="() => goTo('/invoices')"
        :loading="loadingPendingCount"
      />
    </main>
  </fragment>
</template>

<script>
import DashboardItem from '../../components/PatientPortal/DashboardItem.vue';
import ContentTitle from '../../components/ContentTitle.vue';
import GET_FILE_COUNT from '../../graphql/Query/GetMemberFileCount.gql';
import { STATUS as APPOINTMENT_STATUS } from '../../constants/appointment';
import InvalidHCID from '../../components/PatientPortal/InvalidHCID.vue';
import UpcomingAppointmentAlert from '../../components/PatientPortal/UpcomingAppointmentAlert.vue';
import GET_INVOICES_COUNT from '../../graphql/Query/GetInvoiceCount.gql';
import BillingDisabledBanner from '../../components/PatientPortal/BillingDisabledBanner.vue';

export default {
  name: 'Home',

  data() {
    return {
      fileCount: 0,
      pendingCount: 0,
      loadingPendingCount: false,
      loadingFileCount: false,
      loadingAppointmentCount: false,
    };
  },
  components: {
    BillingDisabledBanner,
    ContentTitle,
    DashboardItem,
    InvalidHCID,
    UpcomingAppointmentAlert,
  },
  computed: {
    appointmentCount() {
      const appts = this.$store.getters['patientPortal/upcomingAppointmentCount']({
        filterFunc: ({ status }) => status !== APPOINTMENT_STATUS.cancelled,
      });

      return appts;
    },
    name() {
      const { firstName } = this.$store.getters['patientPortal/currentUser'];
      return firstName;
    },
    healthCardExpired() {
      const { healthCard } = this.$store.getters['patientPortal/currentUser'];
      const now = this.$store.getters.toTimezone();
      const expiryDate = this.$store.getters.toTimezone(healthCard.expiry);
      return expiryDate.diff(now, 'days') < 0;
    },
    isBillingDisabled() {
      return this.$store.getters['patientPortal/isBillingDisabled'];
    },
    upcomingAppointments() {
      return this.$store.getters['patientPortal/upcomingAppointments'];
    },
    stripeCustomerId() {
      const { stripeCustomerId } = this.$store.getters['patientPortal/currentUser'];
      return stripeCustomerId;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path });
    },
    async getPendingCount() {
      const { providerId } = this.$store.getters['patientPortal/currentUser'];
      this.loadingPendingCount = true;

      try {
        const { data } = await this.$apollo.query({
          query: GET_INVOICES_COUNT,
          variables: {
            providerId,
            stripeQuery: {
              customer: this.stripeCustomerId,
              status: 'open',
            },
          },
          fetchPolicy: 'no-cache',
        });
        const { invoices } = data?.getInvoicesCount || {};
        this.pendingCount = invoices.length;
      } catch (error) {
        console.error(error);
      }

      this.loadingPendingCount = false;
    },
    async getFileCount() {
      const { providerId, id: memberId } = this.$store.getters['patientPortal/currentUser'];
      this.loadingFileCount = true;
      try {
        const data = await this.$apollo.query({
          query: GET_FILE_COUNT,
          variables: {
            providerId,
            memberId,
          },
          fetchPolicy: 'no-cache',
        });
        const { getMemberFileCount = [] } = data?.data || {};

        this.fileCount = getMemberFileCount?.length || 0;
      } catch (e) {
        console.error(e);
      }

      this.loadingFileCount = false;
    },
  },
  mounted() {
    this.getFileCount();
    this.getPendingCount();
    this.$store.dispatch('patientPortal/fetchUpcomingAppointments');
  },
};
</script>
