<template>
  <div>
    <v-alert
      type="info"
      prominent
      border="left"
      text
      v-if="canJoinSoon || canJoinNow"
      @click="$vuetify.breakpoint.xsOnly ? goTo('/appointments') : () => {}"
    >
      <span v-if="canJoinSoon" class="break-word">
        {{ $t('patientPortal.notifications.upcomingAppointment', { time: timeUntilAppointment }) }}
      </span>
      <span v-else-if="canJoinNow" class="break-word">
        {{ $t('patientPortal.notifications.appointmentInProgress') }}
      </span>
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-btn color="primary" rounded class="mt-2" @click="goTo('/appointments')">{{
          $t('patientPortal.notifications.viewAppointmentsBtn')
        }}</v-btn>
      </div>
    </v-alert>
  </div>
</template>
<style lang="scss" scoped>
.break-word {
  word-break: break-word;
}
</style>
<script>
export default {
  props: {
    appointment: Object,
  },
  data() {
    return {
      timeUntilAppointment: 0,
    };
  },
  computed: {
    canJoinNow() {
      return this.timeUntilAppointment <= 0;
    },
    canJoinSoon() {
      return this.timeUntilAppointment > 0 && this.timeUntilAppointment < 30;
    },
  },
  methods: {
    updateTimeUntilAppointment() {
      const now = this.$store.getters.toTimezone();
      const appointmentStart = this.$store.getters.toTimezone(this.appointment.startTime);
      this.timeUntilAppointment = appointmentStart.diff(now, 'minutes');
    },
    goTo(path) {
      this.$router.push({ path });
    },
  },
  mounted() {
    this.updateTimeUntilAppointment();
    this.updateInterval = setInterval(this.updateTimeUntilAppointment, 60000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>
