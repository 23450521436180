<template>
  <div>
    <v-alert type="error" prominent border="left" text>
      {{ $t('patientPortal.notifications.expiredHealthCard') }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {},
  data() {},
  computed: {},
  methods: {},
  mounted() {},
};
</script>
